<script setup lang="ts">
const { t } = useI18n()
const localePath = useLocalePath()

const url = useRequestURL()
</script>

<template>
  <div class="my-10 text-center px-3 sm:px-0 text-xl">
    <UButton
      :label="t('login_to_continue')"
      color="amber"
      size="xl"
      class="rounded-xl px-10 py-3 text-xl"
      @click="navigateTo({ path: localePath('/login'), query: { redirect: url.href } })"
    >
      <template #trailing>
        <UIcon name="i-heroicons-arrow-right-20-solid" />
      </template>
    </UButton>

    <ButtonAccountRegister />
  </div>
</template>
